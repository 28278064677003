import AgoraRTC from "agora-rtc-sdk-ng";
import { getFunctions, httpsCallable } from "firebase/functions";

import { showSnackbar } from "./Snackbar";
import {sendPasswordResetEmail, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { auth, setAgoraToken } from "./firebase";

const controls = {
    volume: 5,
    micOn: false,
    cameraOn: false,
    callAnswered: false,
    callerIdOnCall: "",
    agentUserId: "",
    uidOfAgentOnCall: "",
    uidOfCustomerOnCall: "",
    callDuration:0,
    myInterval:null,
    logOff: AgoraRTC.setLogLevel(4),
    agoraEngine: AgoraRTC.createClient({ mode: "rtc", codec: "vp9" }),
    microphoneTrack: await AgoraRTC.createMicrophoneAudioTrack(),
    appId: "",
    roomId: "",
    channelName: "",
    token: "",
    audioTrackOfCustomer: null,
    audioTrackOfAgent: null,
    rooms: [],
    setView: () => {},
    setLoading: () => {},

    setMicOn: () => {},
    setTime: () => {},


    // Recording INFO
    sid: "",
    resourceId:"",
    timeAnswered:"",
    recordingUID:"",
    historyDocId:"",
}

const initAgoraEngine = () =>{
    // controls.agoraEngine = AgoraRTC.createClient({ mode: "rtc", codec: "vp9" });

    //EVENT HANDLERS

    controls.agoraEngine.on("user-published", async (user, mediaType) =>{
      // Subscribe to the remote user when the SDK triggers the "user-published" event.
      await controls.agoraEngine.subscribe(user, mediaType);
      // console.log("subscribe success");
  
      // Subscribe and play the remote audio track.
      if (mediaType === "audio")
       {
            controls.uidOfCustomerOnCall=user.uid;
            // Get the RemoteAudioTrack object from the AgoraRTCRemoteUser object.
            controls.audioTrackOfCustomer = user.audioTrack;
            // Play the remote audio track. 
            controls.audioTrackOfCustomer.play();
            showSnackbar("Customer connected: " + user.uid);
       }
  
      // Listen for the "user-unpublished" event.
      controls.agoraEngine.on("user-unpublished", user =>
      {
        // console.log(user.uid + "has left the channel");
        showSnackbar("Customer has left the call.");
      });
    });
}

const answerCall = async (callerId, setTime, onGoingCallBeforeRelaoding) => {

  try{
    controls.setLoading([true, ""])

    controls.callAnswered = true;

    if((controls.token||"").length<2){
      await setAgoraToken();
    }

    // console.log("TOKEN:", controls.token)

    const functions = getFunctions();

    const answerAUserCall = httpsCallable(functions, 'answerAUserCall');
    const startRecording = httpsCallable(functions, 'startRecording');


    let answerStatus = false;


    try{
      await answerAUserCall({nameOfAgent: controls.channelName, callerId: callerId, roomId: controls.roomId}).then(async (result)=>{
      // console.log("RRRRRRR", result.data);
      answerStatus = result.data[0];

      // console.log(answerStatus);

      if(!(onGoingCallBeforeRelaoding || answerStatus)){
        showSnackbar("Call already answered", "error");
        return;
    }

    initAgoraEngine();

    // console.log("appId", controls.appId)

    await controls.agoraEngine.join(controls.appId, controls.channelName, controls.token);
    // console.log("CHANNEL CREATED");
    showSnackbar("Channel created");

    try {
        controls.microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack()
    } catch (error) {
        // console.log(error)
    }

    await controls.agoraEngine.publish(controls.microphoneTrack);
    controls.microphoneTrack.setEnabled(controls.micOn)
    showSnackbar("Call aasigned, customer will now join you.")
    // console.log("AUDI0 PUBLISHED");
    document.getElementById("myPlayer").pause();

    controls.callerIdOnCall = callerId;
    controls.callAnswered = true;
    controls.callDuration = 0;
    controls.myInterval = null;
    controls.historyDocId = result.data[1];
    controls.myInterval = setInterval(()=>{
        setTime(pTime=>{
            const newTime = pTime+1;
            controls.callDuration = newTime;
            return newTime
        });
    }, 1000);

    try{
      // console.log(controls.startRecording);

      const startRecData = {
        appId: controls.appId, 
        channelName:controls.channelName,
        token: controls.token,
        historyDocId: controls.historyDocId};
        // console.log(startRecData);

      startRecording(startRecData).then((result)=>{
          const data = result.data;
          // console.log(data);
          controls.resourceId = data.resourceId;
          controls.sid = data.sid;
          controls.recordingUID = data.recordingUID;
        });
    } catch(err) {
      // console.log("CANNOT START RECORDING", err);
    }

    })
    } catch(err){
      controls.callAnswered = false;
      // console.log("EEEEEERRR",err)
    }

    controls.setLoading([false, ""])
  } catch(err){
    controls.setLoading([false, ""])
    console.log(err);
  }
    
}

const queueBack = async (callerId, newRoomId, newRoomName, setTime, setMicOn) => {

  const functions = getFunctions();
  const handleUserCallQueue = httpsCallable(functions, 'handleUserCallQueue');

  try{
    controls.setLoading([true, ""])

    await handleUserCallQueue({queueBack: true, callerId: callerId, roomId: controls.roomId, newRoomId: newRoomId, newRoomName:newRoomName}).then(async (result)=>{
    // console.log("RRRRRRR", result.data);
    const status = result.data;

    console.log(status);

    showSnackbar(`Customer has been sent to ${newRoomName} room.`)

    if(status){
      endCall(setMicOn, setTime, callerId)
    }

    })
    controls.setLoading([false, ""])

  } catch(err){
    controls.setLoading([false, ""])

  }
  
}

const endCall = async(setMicOn, setTime, id) => {

    const functions = getFunctions();

    const handleUserCallQueue = httpsCallable(functions, 'handleUserCallQueue');
    const stopRecording = httpsCallable(functions, 'stopRecording');

    let endStatus = false;

    try{
      controls.setLoading([true, ""])

      // console.log(controls.roomId);
      try {
        await handleUserCallQueue({
          callerId: id,
          email: "",
          roomId: controls.roomId,
          historyDocId: controls.historyDocId,
        }).then(async (result) => {
            // console.log("RRRRRRR", result.data);
            endStatus = result.data;
            // console.log(endStatus);
            if(!endStatus){
                  showSnackbar("Call cannot be ended.", "error");
                  return;
              }
            })
      } catch (error) {
        
      }
    try {
      controls.microphoneTrack.close();
      await controls.agoraEngine.leave();
    } catch (error) {
    }

    try {
      clearInterval(controls.myInterval);
      controls.myInterval = null;
      setTime(0);
    } catch (error) {
      
    }


    setMicOn(false);
    showSnackbar("Call ended");
    controls.callerIdOnCall = "";
    controls.callAnswered = false;
    stopRecording({
      appId: controls.appId, 
      channelName:controls.channelName,
      recordingUID: controls.recordingUID,
      resourceId: controls.resourceId,
      sid: controls.sid}).then((result)=>{
      
        // console.log(result);
        controls.sid = "";
        controls.resourceId = "";

      });
      controls.setLoading([false, ""])

    } catch (err){
      controls.setLoading([false, ""])

        // console.log(err);
    }
}

const setOnAuthStateChanged = () =>
 onAuthStateChanged(auth, (user) => {

    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      const uid = user.uid;
      controls.agentUserId = uid;
      // console.log("SIGNED IN");
      controls.setView("call");
      controls.setLoading([false,""]);
      // ...
    } else {
      // User is signed out
      // ...
      // console.log("NOT SIGNED IN");
      controls.agentUserId = null;
      controls.setView("signin");
      controls.setLoading([false,""]);
    }
   });

const signIn = (email, password)=> {
  
  controls.setLoading([true,"Signing you in..."]);

  signInWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    // Signed in 
    // const user = userCredential.user;
    // ...
    showSnackbar("Sign in succeesful!")

  })
  .catch((error) => {
    // const errorCode = error.code;
    // const errorMessage = error.message;
  })};

const resetPassword = async (email)=> {
  
    controls.setLoading([true,""]);
  
    if(email.length < 4 || !email.includes("@")){
      showSnackbar("Enter a valid email first!", "error");
      return;
    }

    await sendPasswordResetEmail(auth, email).then(() => {
      // Signed in 
      // const user = userCredential.user;
      // ...
      showSnackbar(`Password reset email sent to your email:${email}`)
      controls.setLoading([false,""]);

  
    })
    .catch((error) => {
      controls.setLoading([false,""]);

      // const errorCode = error.code;
      // const errorMessage = error.message;
    })};

const signUp = (email, password)=> {

  controls.setLoading([true,"Signing up..."]);

  createUserWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    // Signed up 
    // const user = userCredential.user;
    // ...
    showSnackbar("Sign up succeesful!")

  })
  .catch((error) => {
    // const errorCode = error.code;
    // const errorMessage = error.message;
    // ..
  })};


export default controls;

export {initAgoraEngine, answerCall, endCall, queueBack, signIn, signUp, setOnAuthStateChanged, resetPassword};