import * as React from 'react';
// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import CallIcon from '@mui/icons-material/Call';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Stack } from '@mui/material';
import { resetPassword, signIn } from '../controls';
import { showSnackbar } from '../Snackbar';


export default function SignIn(props) {

  // const [forceResetpassword, setForceResetpassword] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if(data.get('password') === "12345678"){
      showSnackbar("Please reset the default password first or change your password", "error");
      return;
    }

    signIn(data.get('email'), data.get('password'))

    // console.log({
    //   email: data.get('email'),
    //   password: data.get('password'),
    // });
  };

  const handleClickPasswordReset = () => {
    const data = new FormData(document.getElementById('myForm'));
    resetPassword(data.get('email'));
  };

  
  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Typography component="h1" variant="h5">
            Sign in to Frecall
          </Typography>
          <Box component="form" id='myForm' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <Stack direction="row">
          <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              sx={{mr:"5px"}}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
          </Stack>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me" />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 0.5, mb: 0.5 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link component="button" onClick={handleClickPasswordReset} variant="body2">
                  Forgot/Reset password?
                </Link>
              </Grid>
              <Grid item>
                <Link component="button" onClick={()=>props.setView("signup")} variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
  );
}
