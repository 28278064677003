import { Avatar, Button, Paper, Slider, Stack } from '@mui/material'
import React from 'react'
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import controls from './controls';
import LogoutIcon from '@mui/icons-material/Logout';
import { auth } from './firebase';
import { showSnackbar } from './Snackbar';


const AudioControls = () => {
  const [value, setValue] = React.useState(controls.volume);

  const handleChange = (event, newValue) => {
    controls.volume = newValue;
    setValue(newValue);
    document.getElementById("myPlayer").volume = newValue/100;

    if(newValue < 5) {
      setTimeout(() => {
        setValue(5);
        controls.volume = 5;
        document.getElementById("myPlayer").volume = 5/100;
      }, 30000);
    }

  };

  const handleMute = ()=>{
    setValue(0);
    controls.volume = 0;
    document.getElementById("myPlayer").volume = 0/100;

    setTimeout(() => {
      setValue(5);
      controls.volume = 5;
      document.getElementById("myPlayer").volume = 5/100;
    }, 30000);

  }
  
  // document.getElementById("myPlayer").volume = value/100;

  return (
    <Paper sx={{border:"1px solid", margin:"0px"}}>
      <Stack spacing={0} sx={{ height:"250px"}} alignItems="center">
      {/* <IconButton><VolumeDownIcon /></IconButton> */}
      <Avatar sx={{ bgcolor: "primary.main", mt:1 }}><LogoutIcon onClick={()=>{auth.signOut(); showSnackbar("Signed Out", "warning")}}/></Avatar>
        <Slider sx={{marginTop:"20px"}} aria-label="Volume" value={value} onChange={handleChange} orientation='vertical'/>
      {/* <IconButton><VolumeUpIcon /></IconButton> */}
         <Button onClick={handleMute} variant='text'><VolumeOffIcon/></Button>
      </Stack>
    </Paper>
  )
}



export default AudioControls
